/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import UserApplication from '../parts/UserApplication';

// eslint-disable-next-line consistent-return, no-unused-vars
function getAppComponent({ location, appContext }) {
  const app = appContext.app || {};
  // We are only going to return an App react component if the user is authenticated
  // and not registered, otherwise we return undefined which means that the base ui
  // plugin will provide its default App react component.
  if (app.userAuthenticated && !app.userRegistered) {
    return UserApplication;
  }
}

const plugin = {
  getAppComponent,
};

export default plugin;
