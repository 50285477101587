/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import _ from 'lodash';
import React from 'react';
import { decorate, computed, runInAction, observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Message } from 'semantic-ui-react';

// expected props
// - account (via props)
// - onCancel (via props) a call back function when the user clicks on Done
class AccountStatusMessage extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.expanded = false;
    });
  }

  get account() {
    return this.props.account;
  }

  handleExpand = () => {
    this.expanded = !this.expanded;
  };

  handleCancel = () => {
    if (!_.isFunction(this.props.onCancel)) return;
    this.props.onCancel();
  };

  render() {
    return (
      <div className="mt2 mb2 animated fadeIn">
        {this.renderAvailable()}
        {this.renderPending()}
        {this.renderError()}
      </div>
    );
  }

  renderAvailable() {
    const account = this.account;

    if (!account.reachableState) return null;
    const expanded = this.expanded;
    const expandText = expanded ? 'less' : 'more';
    const msg = account.statusMessageInfo.message;
    const hasMsg = !_.isEmpty(msg);

    if (!hasMsg) {
      return (
        <Message positive onDismiss={this.handleCancel}>
          <Message.Header>Available</Message.Header>
          <p>The account is reachable and available for use.</p>
        </Message>
      );
    }

    return (
      <Message warning>
        <Message.Header>Available</Message.Header>
        <p>
          The account is reachable but only partially configured. Some studies might be inaccessible.
          <span className="underline ml1 cursor-pointer" onClick={this.handleExpand}>
            {expandText}
          </span>
        </p>
        {expanded && (
          <>
            <p>
              This is usually an indication that the CloudFormation stack that is deployed to AWS account #{account.id}{' '}
              is out of sync with the CloudFormation template generated by the SWB application.
            </p>
            <p>{msg}</p>
          </>
        )}
      </Message>
    );
  }

  renderPending() {
    const account = this.account;
    const expanded = this.expanded;
    const expandText = expanded ? 'less' : 'more';
    const msg = account.statusMessageInfo.message;

    if (!account.pendingState) return null;

    return (
      <Message warning>
        <Message.Header>Not available yet</Message.Header>
        <p>
          The account is in the process of being connected with the application. It is unreachable until the
          CloudFormation stack is successfully deploy.
          <span className="underline ml1 cursor-pointer" onClick={this.handleExpand}>
            {expandText}
          </span>
        </p>
        {expanded && (
          <div className="mt2 animated fadeIn">
            <Message.Header>CloudFormation stack already deployed?</Message.Header>
            {this.renderTips()}
          </div>
        )}
        {expanded && !_.isEmpty(msg) && (
          <div className="mt2">
            <Message.Header>Message received from the server</Message.Header>
            <p>{msg}</p>
          </div>
        )}
      </Message>
    );
  }

  renderError() {
    const account = this.account;
    const expanded = this.expanded;
    const expandText = expanded ? 'less' : 'more';
    const msg = account.statusMessageInfo.message;

    if (!account.errorState) return null;

    return (
      <Message negative>
        <Message.Header>Not available</Message.Header>
        <p>
          The account is unreachable. This is usually an indication of a problem during the CloudFormation stack
          deployment.
          <span className="underline ml1 cursor-pointer" onClick={this.handleExpand}>
            {expandText}
          </span>
        </p>
        {expanded && this.renderTips()}
        {expanded && !_.isEmpty(msg) && (
          <div className="mt2">
            <Message.Header>Message received from the server</Message.Header>
            <p>{msg}</p>
          </div>
        )}
      </Message>
    );
  }

  renderTips() {
    const account = this.account;
    return (
      <Message.List>
        <Message.Item>
          Check if the CloudFormation stack is deployed in the correct AWS account #{account.id}
        </Message.Item>
        <Message.Item>
          Check if the CloudFormation stack is deployed in the correct AWS region &apos;{account.mainRegion}&apos;
        </Message.Item>
        <Message.Item>Try the connection check test again</Message.Item>
      </Message.List>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(AccountStatusMessage, {
  account: computed,
  expanded: observable,
  handleExpand: action,
  handleCancel: action,
});

export default inject()(withRouter(observer(AccountStatusMessage)));
